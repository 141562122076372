import React from 'react'
import MainLayout from '../layouts/MainLayout'

const Home = () => {
  return (
    <MainLayout>
      <main>Page Not Found</main>
    </MainLayout>
  )
}

export default Home
